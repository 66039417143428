@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700;800&display=swap");

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

.mainContainer {
  @apply max-w-[1024px] mx-auto;
}

.tooltip-container {
  position: relative;
}
.react-select__menu {
  max-height: 200px; 
}

.tooltip {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s, transform 0.3s;
  opacity: 1;
  transform: translateY(0);
}

.tooltip-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: transparent;
  cursor: pointer;
  transition: opacity 0.3s;
  opacity: 0;
}

.tooltip-container:hover .tooltip {
  opacity: 1;
  transform: translateY(-10px);
}

.tooltip-container:hover .tooltip-overlay {
  opacity: 1;
}


h1 {
  font-family: "Outfit", sans-serif;
  font-size: 32px;
}
h1,
h2,
h3,
h5,
h4,
h6,
p {
  font-family: "Outfit", sans-serif;
}
h1 {
  font-size: 32px;
  font-weight: 700;
}
h2 {
  font-size: 28px;
  font-weight: 700;
}
h3 {
  font-size: 24px;
  font-weight: 700;
}
h4 {
  font-size: 20px;
  font-weight: 500;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 15px;
}
.all {
  transition: margin-left 0.3s ease;
}
.all.opened-sidebar {
  margin-left: 170px;
}

[type="text"]:focus,
input:where(:not([type])):focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-shadow: none;
}
/* .custom-scroll-box {
  transform: rotate(180deg);
}

.custom-scroll-box > div {
  transform: rotate(-180deg);
} */

@media (max-width:1024px){
  h1 {
    font-size: 28px;
    font-weight: 700;
  }
  h2 {
    font-size: 24px;
    font-weight: 700;
  }
  h3 {
    font-size: 22px;
    font-weight: 700;
  }
  h4 {
    font-size: 18px;
    font-weight: 500;
  }
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 14px;
  }

}
@media (max-width:768px){
  .all.opened-sidebar {
    margin-left: 0px;
  }
  h1 {
    font-size: 26px;
    font-weight: 700;
  }
  h2 {
    font-size: 22px;
    font-weight: 700;
  }
  h3 {
    font-size: 20px;
    font-weight: 700;
  }
  h4 {
    font-size: 15px;
    font-weight: 500;
  }
  h5 {
    font-size: 14px;
  }
  h6 {
    font-size: 12px;
  }
}
#hubspotForm form {
  @apply !w-full !flex !flex-col !items-start !gap-5;
}
#hubspotForm form .form-columns-1 {
  @apply !w-full;
}
#hubspotForm form fieldset {
  @apply !w-full;
}
#hubspotForm form .hs-form-field {
  @apply !w-full;
}


.hs-input:is(input[type="text"], input[type="email"], input[type="submit"], select, textarea) {
  @apply !w-full;
}

.hs-button {
  @apply !w-full !bg-troo-primary !text-troo-white !p-4 !text-center
}

.otp-input[type="number"]::-webkit-inner-spin-button,
.otp-input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.onOffCheck {
  @apply relative w-[68px] h-8 p-px bg-white border-troo-primary/50 text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-troo-primary checked:border-troo-primary before:inline-block before:size-7 before:bg-troo-primary checked:before:bg-troo-white before:border-troo-primary before:checked:border-troo-primary before:translate-x-0 checked:before:translate-x-9  before:rounded-full before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 ring-0 outline-none focus:ring-0
}

.tableCheckbox {
  @apply text-troo-primary size-5 rounded
}